import React from "react"

import Loading from '../components/loading';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  render() {
    if (this.state.loading) {
      return (<Loading />);
    }

    return (<Loading />)
  }
}

export default IndexPage
